import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import CTABanner from "../components/Common/CTABanner"
import Footer from "../components/App/Footer"
import SEO from "../components/App/SEO"

const Terms = () => {
  return (
    <Layout>
      <SEO title="Terms &amp; Conditions | Oxygen - Home Loans Made Simple" />
      <Navbar />
      <PageBanner
        pageTitle=""
        pageSubTitle="By visiting and using this website you agree as follows:"
      />
      <section className="terms-of-service-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="terms-of-service-content">
                <h3 className="pt-lg-3 pt-md-0">Oxygen Referrer Terms and Conditions</h3>
                <p>
                  If you refer potential borrowers to Oxygen Home Loans (us/we),
                  you will be deemed to have agreed to the terms set out below.
                </p>
                <p>You must:</p>
                <ul>
                  <li>
                    only engage in credit activities as a referrer incidentally
                    to another business you are carrying on;
                  </li>
                  <li>
                    only inform the consumer that we are able to arrange loans
                    and leases but not specify any particular product, and not
                    provide any recommendations or advice concerning loans or
                    leases;
                  </li>
                  <li>not charge a fee to the consumer for the referral;</li>
                  <li>
                    inform the consumer of any commissions or other benefits you
                    may receive;
                  </li>
                  <li>
                    obtain the consent of the consumer to pass their name,
                    contact details and a short description of the purpose for
                    which the consumer may want the credit or lease;
                  </li>
                  <li>
                    pass the consumer’s contact details to us within five
                    business days of informing the consumer that we are able to
                    arrange loans and leases but not any specified particular
                    product; and
                  </li>
                  <li>
                    not be listed on the ASIC banned or disqualified register.
                  </li>
                </ul>
                <p>
                  Any referrals to an Oxygen broker will be regarded as
                  acknowledgement of, and agreement to, the terms and conditions
                  outlined above.
                </p>

                <h3>Disclaimer</h3>
                <p>
                  Oxygen Broking Services Pty Ltd (“Oxygen”) and its directors,
                  officers, employees, agents and related entities responsible
                  for maintaining this website believe that the information
                  contained on this website is correct. However, no
                  representation or warranties of any nature whatsoever are
                  given, intended or implied, and you should rely on your own
                  inquiries as to the accuracy of any information or material
                  available from this website.{" "}
                </p>
                <p>
                  Please note that information provided on this website is
                  general information only, is subject to change without notice
                  and should not be relied on as a substitute for legal,
                  financial, real estate or other expert advice. Oxygen
                  disclaims all liability and responsibility, including for
                  negligence, for any direct or indirect loss or damage suffered
                  by any person arising out of any use of this website or any
                  information or material available from it.
                </p>
                <h3>Copyright</h3>
                <p>
                  All material available or accessible from this website is
                  copyright material owned by Oxygen and related entities. Apart
                  from fair dealing permitted by the Copyright Act 1968,
                  visitors to the site are granted permission to download and
                  display any of the website material for private purposes.{" "}
                </p>
                <p>
                  For intended use of any copyright material beyond private or
                  research uses, permission must be sought directly from Oxygen.
                  If permission is given, it will be subject to a requirement
                  that the copyright owner’s name and interest is acknowledged
                  when reproducing the whole or part of any copyright material.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default Terms
